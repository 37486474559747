<template>
  <el-card shadow="never">
    <div slot="header" class="clearfix">
      <el-form
          id="change"
          status-icon
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
      >
        文章标题：
        <el-input
            style="width: 200px; margin-right: 10px"
            class="search"
            v-model="asearch1"
            prefix-icon="el-icon-search"
            clearable
            placeholder="请输入文章名称"
        />
        文章状态：
        <el-select v-model="value" placeholder="请选择" clearable  style="margin-right: 10px">
          <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.label"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="getArticleList()" style="background: #1d90ff; border:none">查询</el-button>
        <el-button type="success" @click="addArticle()"  style="background: #1d90ff; border:none">添加文章</el-button>
      </el-form>
    </div>
    <el-table stripe :data="tableData" style="width: 100%; margin-top:10px" border>
      <el-table-column prop="id" label="文章编号" width="100" align="center">
      </el-table-column>
      <el-table-column prop="title" label="文章标题" width="300" align="center">
      </el-table-column>
      <el-table-column prop="keyword" label="关键词" width="150" align="center">
      </el-table-column>
      <el-table-column prop="description" label="描述" width="350" align="center">
      </el-table-column>
      <el-table-column prop="nickName" label="撰稿人" width="150" align="center">
        <template slot-scope="scope">
          <el-tag v-show="scope.row.nickName" type="info" >{{ scope.row.nickName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="80" align="center" >
        <template slot-scope="scope">
          <el-tag effect="dark" v-show="scope.row.status == 0" type="info">待审核</el-tag>
          <el-tag effect="dark" v-show="scope.row.status == 1">已审核</el-tag>
          <el-tag effect="dark" v-show="scope.row.status == 2" type="success">已上架</el-tag>
          <el-tag effect="dark" v-show="scope.row.status == 3" type="danger">已下架</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="updatedTime" label="更新时间" width="150" align="center"></el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button type="success" style="background: #1d90ff; border:none"  size="mini" @click="editArticle(scope.row.id)">编辑
          </el-button
          >
        </template>
      </el-table-column>

    </el-table>
    <div style="margin-top: 20px; text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 50, 60]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total=pagetotal>
      </el-pagination>
    </div>

  </el-card>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import $ from "jquery";
import Cookies from "js-cookie";

export default {
  methods: {
    adminUpdateArticleStatus(id, status) {
      this.$api.post(
          "/article/admin/updateArticleStatus",
          null,
          {
            id: id,
            status: status
          },
          (successRes) => {
            console.log(successRes);
            if (successRes.status == 200) {
              this.$message('操作成功');
              //this.$router.push({name: "articleList"});
              this.getArticleList();
            } else {
              this.$message(successRes.message);
            }
          },
          (failureRes) => {
            this.$message('操作失败');
          }
      );
    },
    getUserInfoByUserName() {
      const username = Cookies.get("username");
      this.$api.get(
          "/user/getUserInfoByUserName",
          null,
          {
            username: username
          },
          (successRes) => {
            console.log(successRes);
            this.userInfo = successRes.data;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    getArticleList() {
      this.$api.get(
          "/article/list",
          null,
          {
            title: this.asearch1,
            status: this.value,
            audit: false
          },
          (successRes) => {
            var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
             exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
            }
            this.tableData = exf;
            this.pagetotal =  successRes.data.total;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },

    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
       this.spagetotal=val;
         this.$api.get(
          "/article/list",
          null,
          {
          page:this.nowpagetotal,
          record:this.spagetotal,
          title: this.asearch1,
            status: this.value,
          },
          (successRes) => {
            this.pagetotal =  successRes.data.total;
           var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
            exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
            }
            this.tableData = exf;

          },
          (failureRes) => {
            console.log(failureRes);
          }
      );

        console.log(val);
      },
      handleCurrentChange(val) {
        this.nowpagetotal=val;
        this.$api.get(
          "/article/list",
          null,
          {
            page:this.nowpagetotal,
           record:this.spagetotal,
           title: this.asearch1,
            status: this.value,
          },
          (successRes) => {
            this.pagetotal =  successRes.data.total;
            var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
            exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
            }
            this.tableData = exf;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
      },
    addArticle() {
      this.$router.push({name: "articleAdd", params: {userId: 123}});
    },
    editArticle(id) {
    
      this.$router.push({ path: 'articleedit',query: {id: id}});

    },
  },
  data() {
    return {
      options: [
        {
          id: 0,
          value: "选项1",
          label: "待审核",
        },
        {
          id: 1,
          value: "选项2",
          label: "已审核",
        },
        {
          id: 2,
          value: "选项3",
          label: "已上架",
        },
        {
          id: 3,
          value: "选项4",
          label: "已下架",
        },
      ],
      value:'',
      tableData: [],
      asearch1: "",
      asearch2: "",
      asearchdata: {},
      userInfo: {},
      updateStatus: 0,
      pagetotal:0,
      spagetotal:'10',
      nowpagetotal:'1',
      currentPage4: 1
    };
  },
  mounted() {
    this.getArticleList();
    this.getUserInfoByUserName();
  },
};
</script>